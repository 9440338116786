<template>
    <div v-for="item in products" :key="item.title">
        <ConsoleTrackersCard
            :id="item.id"
            :copy="item.copy"
            :prices="item.prices"
            :selects="item.selects"
            :price="item.price"
            :img="item.img"
            :itemUrl="item?.itemUrl ?? null"
        />
    </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import ConsoleTrackersCard from './ConsoleTrackersCard.vue';

const DEV_GEN2_LIST = new Map([
  ['console_trackers', '41683425067097'],
  ['console_trackers_annual_bundle_S', '41604714561625'],
  ['console_trackers_annual_bundle_L', '41604714594393'],
]);

const PROD_GEN2_LIST = new Map([
  ['console_trackers', '45413107138796'],
  ['console_trackers_annual_bundle_S', '45413115756780'],
  ['console_trackers_annual_bundle_L', '45413115789548'],
]);

export default {
  name: 'Gen2Upgrade',
  components: {
    ConsoleTrackersCard,
  },
  setup() {
    const store = useStore();
    const customerId = computed(() => store.state?.account?.customer?.id);
    function getUrl(uid) {
      const bundleId = process.env.VUE_APP_IS_DEV === 'true' ? DEV_GEN2_LIST.get(uid) : PROD_GEN2_LIST.get(uid);
      const url = `${process.env.VUE_APP_SHOP_BUNDLE_URL}${bundleId}&coupon=UPGRADE1TO2G7X9Z4&id=${customerId.value}`;

      return url;
    }

    const products = computed(() => ([
      {
        id: 'console_trackers',
        copy: {
          title: 'FightCamp console & 4-tracker bundle',
          description: {
            text: "What You'll Get:",
            items: [
              'FightCamp Console (includes 1 Pair of Trackers)',
              'Free Extra Pair of FightCamp Trackers ($99 value)',
              'Free Shipping',
            ],
          },
          cta: 'Upgrade',
        },
        prices: {
          price: '$249',
          oldPrice: '$399',
        },
        img: {
          src: 'https://a.storyblok.com/f/152150/3240x3240/81141aadb8/fc-console-trackers-upgrade-004.png',
          alt: 'FightCamp console + trackers package',
        },
        itemUrl: getUrl('console_trackers'),
      },
      {
        id: 'console_trackers_bundle',
        copy: {
          title: 'Bundle With An Annual Membership & Get More',
          priceDescription: '(including 12 months of membership)',
          description: {
            text: "What You'll Get:",
            items: [
              'FightCamp Console (includes 1 Pair of Trackers)',
              'Free Extra Pair of FightCamp Trackers ($99 value)',
              '12-month FightCamp Membership',
              '1 Pair of FightCamp Gloves',
              '$50 FightCamp Apparel Store Credit*',
              'Free Shipping',
            ],
          },
          cta: 'Upgrade with Annual',
          apparelCredit: '*Apparel store credit is valid for a single use, and expires June 30, 2025',
        },
        prices: {
          price: '$670.20',
          oldPrice: '$820.20',
        },
        selects: [
          {
            name: 'Glove Size',
            key: 'gPair1',
            hint: 'Choose Glove size',
            options: [{
              key: 's',
              name: 'Small',
              htc: 'Ideal for small to medium size hands.',
              itemUrl: getUrl('console_trackers_annual_bundle_S'),
            },
            {
              key: 'l',
              name: 'Large',
              htc: 'Ideal for larger hands.',
              itemUrl: getUrl('console_trackers_annual_bundle_L'),
            }],
          },
        ],
        img: {
          src: 'https://a.storyblok.com/f/152150/3240x3240/4fbbfce81b/fc-console-trackers-bundle-upgrade-004.png',
          alt: 'FightCamp console + trackers package',
        },
      },
    ]));

    return {
      products,
    };
  },
};
</script>
