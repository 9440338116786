<template>
  <div class="not-authorized">
    <p class="not-authorized-text">{{ error }} </p>
    <p class="not-authorized-text"> Please <a target="_blank" rel="noopener noreferrer" href="https://support.joinfightcamp.com/hc/en-us">contact</a>
  customer support if you have any questions. </p>
  </div>
</template>

<script>
export default {
  name: 'NotAuthorized',
  props: {
    error: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.not-authorized {
  text-align: center;
  padding: 1rem;

  &-text {
    font-size: 20px;
  }
}
</style>
