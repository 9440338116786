<template>
  <main class="dashboard-content">
    <h1 class="dashboard-title" >{{ title }}</h1>
    <Gen2Upgrade v-if="!isGen2PromoCustomer" />
    <AnnualUpsell v-if="subs && !!subs.length" />
    <ActivationCode />
    <AccessoryAddOns v-if="showConnectAddOnSection" />
    <PaymentMethod />
    <BillingInformation />
    <Memberships />
    <Invoices />
  </main>
</template>

<script>
import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useAnalytics } from '../plugins/Segment';
import BillingInformation from './BillingInformation.vue';
import Memberships from './Memberships.vue';
import PaymentMethod from './PaymentMethod.vue';
import ActivationCode from './ActivationCode.vue';
import Invoices from './Invoices.vue';
import Gen2Upgrade from './Gen2Upgrade.vue';
import AnnualUpsell from './AnnualUpsell.vue';
import AccessoryAddOns from './AccessoryAddOns.vue';

export default {
  name: 'Dashboard',
  components: {
    BillingInformation,
    Memberships,
    PaymentMethod,
    ActivationCode,
    Invoices,
    AnnualUpsell,
    AccessoryAddOns,
    Gen2Upgrade,
  },
  setup() {
    const analytics = useAnalytics();
    const store = useStore();

    const customer = computed(() => store.state.account.customer);

    onMounted(async () => {
      analytics.emailIdentified(customer.value.email);
      analytics.pageViewed({ name: 'Dashboard' });
    });

    const isGen2PromoCustomer = computed(() => store.getters.isGen2PromoCustomer);
    const title = computed(() => (isGen2PromoCustomer.value ? 'Manage Your FightCamp Membership' : 'Members Only Offers'));

    return {
      showConnectAddOnSection: computed(() => store.getters.showConnectAddOnSection),
      subs: computed(() => store.getters.currentSubscriptions),
      isGen2PromoCustomer,
      title,
    };
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  &-content {
    text-align: start;
    margin: 2rem;

    @media #{$tablet} {
      width: 80%;
    }

    @media #{$desktop} {
      padding-left: 6rem;
      max-width: 944px;
    }
  }

  &-title {
    font-family: $font-stack-regular;
    text-align: center;
    text-transform: capitalize;
    font-size: 1.75rem;

    @media #{$tablet-up} {
      text-align: left;
    }
  }

  &-title--variant {
    font-family: $font-stack-regular;
    text-align: left;
    text-transform: capitalize;
    font-size: 1.75rem;
  }
}
</style>
