<template>
  <Layout>
    <div class="wrapper">
      <div v-if="loading" class="wrapper__loading">
        <font-awesome-icon icon="spinner" size="3x" spin/>
      </div>
      <div v-else>
        <NotAuthorized v-if="notAuthorized" :error="error"/>
        <Dashboard v-else/>
      </div>
    </div>
  </Layout>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useAuth0 } from '@auth0/auth0-vue';
import Dashboard from './Dashboard.vue';
import NotAuthorized from './NotAuthorized.vue';
import Layout from './Layout.vue';

export default {
  name: 'LoggedIn',
  components: {
    Layout,
    Dashboard,
    NotAuthorized,
  },
  setup() {
    const store = useStore();
    const { logout, isLoading } = useAuth0();

    return {
      logout,
      loading: computed(() => store.state.initializing && !isLoading.value),
      notAuthorized: computed(() => store.state.notAuthorized),
      error: computed(() => store.state.accountError),
    };
  },
};
</script>

<style lang="scss" scoped>
  .wrapper {
    width: 100%;

    &__loading {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;

      svg {
        color: $color-dark-gray;
      }
    }
  }
</style>
