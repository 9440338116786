<template>
  <div class="gen2-product__card" v-track-view="`Gen2Product_Card_${id}`">
    <div class="gen2-product__image-container">
      <div class="gen2-product__image-card">
        <img :src="img.src" :alt="img.alt" height="297" width="297" fetchpriority="high">
        <div class="gen2-product__image-content">
          <p class="gen2-product__price">{{ prices.price }} <span class="gen2-product__price--old">{{ prices.oldPrice }}</span></p>
          <p v-if="copy.priceDescription" class="gen2-product__small-text">{{ copy.priceDescription }}</p>
          <div class="gen2-product__size-selector">
            <FCSelect
              v-if="selects"
              v-model="selected"
              name="size"
              hint="size"
              :label="selects[0].hint"
              :required="true"
              :options="selects[0].options" />
          </div>
          <a
            v-if="!selects"
            class="gen2-product__button button-normal button-layout"
            :class="hasUnpaidSubscription ? 'button-disabled' : 'button-cta'"
            :disabled="hasUnpaidSubscription"
            target="_blank"
            rel="noopener noreferrer"
            :href="itemUrl"
            @click="hasUnpaidSubscription ? $event.preventDefault() : createGen2Link()"
            v-track-click="`Gen2Upgrade_Upgrade_${id}`">
            {{ copy.cta }}
          </a>
          <a
            v-else
            target="_blank"
            class="gen2-product__button button-normal button-layout"
            :class="hasUnpaidSubscription ? 'button-disabled' : 'button-cta'"
            :disabled="hasUnpaidSubscription"
            rel="noopener noreferrer"
            :href="selectsUrl"
            @click="hasUnpaidSubscription ? $event.preventDefault() : createGen2Link()"
            v-track-click="`Gen2Upgrade_Upgrade_${id}`">
            {{ copy.cta }}
          </a>
        </div>
      </div>
    </div>
    <div class="gen2-product__text">
      <p v-if="taggedCustomer" class="gen2-product__small-text gen2-product__hide-tablet-up">if you're not automatically redirected, please use account email <span class="gen2-product__copy-alert">{{ email }}</span> to purchase with this link <a :href="generatedUrl" target="_blank" rel="noopener noreferrer" class="gen2-product__copy-alert gen2-product__url">{{ generatedUrl }}</a> </p>
      <h2 class="gen2-product__title">{{ copy.title }}</h2>
      <p class="gen2-product__description">{{ copy.description.text }}</p>
      <ul class="gen2-product__ul">
        <li v-for="item in copy.description.items" :key="item" class="gen2-product_li">{{ item }}</li>
      </ul>
      <p v-if="copy.apparelCredit" class="gen2-product__small-text">{{ copy.apparelCredit }}</p>
      <p v-if="urlError" class="gen2-product__error" v-html="urlError" />
      <p v-if="taggedCustomer" class="gen2-product__small-text gen2-product__hide-mobile">if you're not automatically redirected, please use account email <span class="gen2-product__copy-alert">{{ email }}</span> to purchase with this link <a :href="generatedUrl" target="_blank" rel="noopener noreferrer" class="gen2-product__copy-alert gen2-product__url">{{ generatedUrl }}</a> </p>
      <p v-if="hasUnpaidSubscription" class="gen2-product__error">Please update your card on file to pay for current subscription in order to upgrade.</p>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import { apiClient } from '../services/apiClient';
import FCSelect from './FCSelect.vue';

export default {
  name: 'ConsoleTrackersCard',
  components: {
    FCSelect,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    copy: {
      type: Object,
      required: true,
    },
    prices: {
      type: Object,
      required: true,
    },
    selects: {
      type: Array,
      required: false,
    },
    img: {
      type: Object,
      required: true,
    },
    itemUrl: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const store = useStore();
    const urlError = ref('');
    const selected = ref('s');
    const email = computed(() => store.state.account.customer.email);
    const taggedCustomer = ref(false);

    const selectsUrl = computed(() => {
      if (!props.selects) return null;
      return selected.value === 's' ? props.selects[0].options[0].itemUrl : props.selects[0].options[1].itemUrl;
    });
    const generatedUrl = computed(() => {
      if (props.itemUrl) return props.itemUrl;
      return selectsUrl.value;
    });

    async function createGen2Link() {
      try {
        await apiClient.post('/upgradePromo', { email: email.value });
        taggedCustomer.value = true;
        urlError.value = '';
      } catch (err) {
        if (err.response.status === 404) {
          urlError.value = 'Sorry there has been an issue. Please <a target="_blank" rel="noopener noreferrer" href="https://support.joinfightcamp.com">contact</a> customer support to get your activation code.';
        } else {
          urlError.value = 'There was an error upgrading your account. Please try again or contact support.';
        }
      }
    }

    const hasUnpaidSubscription = computed(() => store.getters.hasUnpaidSubscription);

    return {
      generatedUrl,
      createGen2Link,
      taggedCustomer,
      hasUnpaidSubscription,
      selected,
      urlError,
      email,
      selectsUrl,
    };
  },
};
</script>

<style lang="scss" scoped>
.gen2-product {
  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $color-white;
    color: $color-blue;
    margin-bottom: 5rem;

    @media #{$tablet-up} {
      padding: 1rem 2rem;
      flex-direction: row;
      box-shadow: 0 0.25rem 0.5rem rgba(15, 30, 60, 0.1);
      border-radius: $input-radius;
    }
  }

  &__image {
    &-container {
      width: 350px;
      padding: 2rem;
      border-radius: $input-radius;

      img {
        border-top-right-radius: $input-radius;
        border-top-left-radius: $input-radius;
        width: 100%;
      }

      @media #{$tablet-up} {
        background-position: center;
        background-size: cover;
        background-image: url('../assets/backgroundContainer.png');
      }
    }

    &-card {
      border-radius: $input-radius;
      background-color: $color-egg-shell;

      @media #{$tablet-up} {
        background-color: $color-white;
      }
    }

    &-content {
      padding: 1rem;
    }

    &-text {
      font-size: 0.875rem;
      margin: 0;
    }
  }

  &__text {
    @media #{$tablet-up} {
      display: flex;
      flex-direction: column;
      align-items: center;
      $left-size-width: 320px;

      flex: 1 $left-size-width;

      & > * {
        max-width: $left-size-width;
      }
    }
  }

  &__title {
    width: 100%;
    text-align: left;
    font-family: $font-stack-heading;
    margin-bottom: 0.5rem;

    @media #{$tablet-up} {
      font-size: 2.25rem;
    }
  }

  &__description {
    width: 100%;
    margin: 0;
  }

  &__ul {
    margin-top: 0.25rem;
    padding: 0 0 0 1.5rem;
  }

  &__li {
    padding: 0 0 0 1rem;
  }

  &__size-selector {
    margin-top: 1rem;
  }

  &__error {
    font-size: 0.875rem;
    color: $color-red;
    margin: 0;
  }

  &__button {
    margin-top: 1rem;
    padding: 1rem;
    width: 100%;
  }

  &__price {
    font-family: $font-stack-heading;
    font-size: 1.375rem;
    margin: 0;
    text-transform: uppercase;
    color: $color-black;

    &--old {
      text-decoration: line-through;
    }
  }

  &__small-text {
    color: $color-blue;
    margin: 0;
    font-size: 0.85rem;
    word-break: normal;
    overflow-wrap: break-word;
    padding-top: 0.25rem;
  }

  &__copy-alert {
    font-family: $font-stack-bold;
    color: $color-blue;
  }

  &__hide-mobile {
    display: none;

    @media #{$tablet-up} {
      display: block;
    }
  }

  &__hide-tablet-up {
    display: block;

    @media #{$tablet-up} {
      display: none;
    }
  }

  &__url {
    word-break: break-all;
  }
}
</style>
