<template>
  <section>
    <SectionHeading text="Activation Code" />
    <div v-if="isCodePresent">
      <p>
        Use your unique activation code to activate your punch trackers and to connect friends & family members in your household:
      </p>
      <div class="activation-code__code-wrapper">
        <p class="activation-code__code" @click="copyText" v-track-click="'CopyActivationCode'">{{ activationCode }}</p>
        <button @click="copyText" v-track-click="'CopyActivationCode'" class="activation-code__copy-button button-layout button-cta">{{ buttonText }}</button>
      </div>
      <p v-if="errorMessage" class="activation-code__error">{{ errorMessage }}</p>
    </div>
    <p v-else>
      Sorry there has been an issue. Please <a target="_blank" rel="noopener noreferrer" href="https://support.joinfightcamp.com">contact</a> customer support to get your activation code.
    </p>
  </section>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import SectionHeading from './SectionHeading.vue';

export default {
  name: 'ActivationCode',
  components: {
    SectionHeading,
  },
  setup() {
    const store = useStore();
    const activationCode = computed(() => store.getters.activationCode);
    const isCodePresent = computed(() => !!activationCode.value.length);

    const buttonText = ref('Copy');
    const errorMessage = ref(null);
    function copyText() {
      const cb = navigator.clipboard;
      cb.writeText(activationCode.value)
        .then(() => {
          buttonText.value = 'Copied!';
          toggleButtonText();
        })
        .catch(() => {
          errorMessage.value = 'Sorry, could not copy text';
        });
    }

    function toggleButtonText() {
      setTimeout(() => {
        buttonText.value = 'Copy';
      }, 2000);
    }

    return {
      activationCode,
      isCodePresent,
      copyText,
      buttonText,
      errorMessage,
    };
  },
};
</script>

<style lang="scss" scoped>
.activation-code {
  &__code-wrapper {
    display: flex;
    align-items: center;
    max-width: 400px;
  }

  &__code {
    margin-right: 1rem;
    font-size: 18px;

    &:hover {
      cursor: copy;
    }
  }

  &__copy-button {
    margin: 0;
    height: 42px;
    width: 74px;

    &:hover {
      cursor: copy;
    }
  }

  &__error {
    color: $color-red;
  }
}
</style>
