<template>
  <section class="billing-information">
    <SectionHeading text="Billing Information"/>
    <div v-if="isEditAddress && card" class="billing-information-edit">
      <AddressForm
        :name="name"
        :address="billingDetails"
        :loading="loading"
        @addressFormSubmitted="submitAddressForm"
        @addressFormCancelled="toggleEdit"
      />
    </div>
    <div v-else-if="!card" class="billing-information-no-card">
      <p>Please update your card.</p>
    </div>
    <div v-else class="billing-information-display">
      <Billing :card="card" :email="email" :name="name" />
      <IconAction
        v-track-click="`${$options.name}_EditBtn`"
        @buttonClicked="toggleEdit"
        icon="pen"
        label="Edit billing address"/>
    </div>
    <p v-if="mutateError" class="error" v-html="mutateError" />
  </section>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import AddressForm from './AddressForm.vue';
import Billing from './Billing.vue';
import IconAction from './IconAction.vue';
import SectionHeading from './SectionHeading.vue';
import { useMembershipContext } from '../composables/useMembershipContext';
import { useAccount } from '../composables/useAccount';

export default {
  name: 'BillingInformation',
  components: {
    AddressForm,
    Billing,
    IconAction,
    SectionHeading,
  },
  setup() {
    const store = useStore();
    const { card, name, email } = useAccount();

    const loading = computed(() => store.state.requestInProgress);
    const billingDetails = computed(() => {
      if (card?.value?.billing_details && card?.value?.billing_details?.address) {
        return card.value.billing_details.address;
      }

      return '';
    });

    const currentSubscription = computed(() => {
      const [sub] = store.getters.currentSubscriptions || [];
      return sub;
    });

    const { mutate: updateTaxRate, error: mutateError } = useMembershipContext(currentSubscription?.value?.id).updateTaxRate;

    const isEditAddress = ref(false);
    const toggleEdit = () => {
      isEditAddress.value = !isEditAddress.value;
    };
    async function submitAddressForm(address) {
      if (card.value) {
        // we do this call first to check if the zipcode/state combo is valid
        await updateTaxRate({
          price: currentSubscription.value.plan.amount,
          address,
        });

        if (mutateError.value) {
          isEditAddress.value = false;
          return;
        }

        await store.dispatch('updateAddress', {
          paymentMethodId: card.value.id,
          updatedDetails: address,
        });

        isEditAddress.value = false;
      }
    }

    return {
      isEditAddress,
      toggleEdit,
      submitAddressForm,
      loading,
      card,
      email,
      name,
      billingDetails,
      mutateError,
    };
  },
};
</script>

<style lang="scss" scoped>

.billing-information {
  &-edit {
    @media #{$desktop} {
      width: 75%;
    }
  }
}
</style>
